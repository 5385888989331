<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">老师管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">老师列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="老师姓名" class="searchboxItem ci-full">
              <span class="itemLabel">老师姓名:</span>
              <el-input
                v-model="teacherName"
                type="text"
                size="small"
                clearable
                placeholder="请输入老师姓名"
              />
            </div>
            <el-button
              style="margin-left:20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              style="margin-left:20px"
              class="bgc-bv"
              round
              @click="getExport()"
              >导出</el-button
            >
            <div class="btnBox" style="margin-left:20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >添加老师</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="老师姓名"
                align="center"
                prop="teacherName"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                label="性别"
                align="center"
                prop="sex"
                show-overflow-tooltip
                min-width="100"
              >
              <template slot-scope="scope">
                  {{scope.row.sex == '1'? '男':'女'}}
              </template>
              </el-table-column>

              <el-table-column
                label="联系方式"
                align="center"
                prop="mobile"
                show-overflow-tooltip
                min-width="100"
              />
              <!-- <el-table-column
                label="教龄"
                align="center"
                prop="teachingAge"
                show-overflow-tooltip
                min-width="100"
              /> -->
              <el-table-column
                label="添加时间"
                align="center"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:7px 10px"
                    @click.stop="() =>handleDelete(scope.row.teacherId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleCreate('edit', scope.row.teacherId)"
                    >编辑
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogFormVisible: false,
      teacherName: "",
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        teacherName: this.teacherName,
      };
      this.doFetch(
        {
          url: "/teacher/mini-teacher/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //新增
    handleCreate(stu, teacherId = "") {
      this.$router.push({
        path: "/web/miniApp/teacherEdit",
        query: { teacherId, stu },
      });
    },
    //导出
    getExport() {
        const params = {
        teacherName: this.teacherName,
      };
        this.$post('/teacher/mini-teacher/listExport',params,3000,true,2).then(ret => {
            if(ret.status == 0) {
                window.open(ret.data)
            }
        }).catch(err => {
            return
        })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //删除
    handleDelete(teacherId) {
      this.doDel({
        url: "/teacher/mini-teacher/delete",
        msg: "确定删除老师吗？",
        ps: {
          type: "post",
          data: { teacherId },
        },
      },true,2);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>
<style lang="less" scoped></style>